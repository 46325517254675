import './reseter.css';
import './App.css';
import React, { useState } from 'react';
// import { useRef, useEffect } from 'react';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

function App() {

  const [Email, setEmail] = useState(''); 

  // gsap.registerPlugin(ScrollTrigger);
  // const ref = useRef(null);
  // useEffect(() => {
  //   const element = ref.current;
  //   gsap.to(".image-before", {
  //     autoAlpha: 0,
  //     scrollTrigger: {
  //       trigger: element.querySelector(".header"),
  //       start: "top top",
  //       end: "bottom top",
  //       scrub: true
  //     }
  //   });
  //   gsap.to(".image-after", {
  //     width: '100vw',
  //     height: '100vh',
  //     ease: "in",
  //     scrollTrigger: {
  //       trigger: element.querySelector('.music'),
  //       start: 'center 51%',
  //       end: 'bottom center',
  //       toggleActions: 'play none none reverse'
  //     }
  //   });
  //   if (matchMedia('(min-width: 1000px)').matches) {
  //   gsap.to(".about h2", {
  //     autoAlpha: 0,
  //     ease: "in",
  //     scrollTrigger: {
  //       trigger: element.querySelector('.about'),
  //       start: 'bottom 90%',
  //       end: 'bottom 50%',
  //       scrub: true,
  //       toggleActions: 'play none none reverse'
  //     }
  //   });
  //   gsap.to(".about p", {
  //     autoAlpha: 0,
  //     ease: "in",
  //     duration: 10,
  //     scrollTrigger: {
  //       trigger: element.querySelector('.about'),
  //       start: 'bottom 90%',
  //       end: 'bottom 50%',
  //       scrub: true,
  //       pin: true,
  //       toggleActions: 'play none none reverse'
  //     }
  //   });
  //   gsap.fromTo(".image-after", 
  //   {
  //     width: '100vw',
  //     height: '100vh'
  //   },
  //   {
  //     width: '0vw',
  //     height: '0vh',
  //     ease: "power2.inOut",
  //     duration: 1,
  //     scrollTrigger: {
  //       trigger: element.querySelector('.newsletter'),
  //       start: 'top bottom',
  //       end: 'top 1%',
  //       toggleActions: 'play none none reverse',
  //       scrub: true
  //     }
  //   });
  // }
  // }, []);

  return (
    <div className="App" /*ref={ref}*/>
      <div className="banner">
        <a href="#music-section"><p>ACACIA</p></a>
      </div>
      {/* <div className="background">
        <div className="image-before"></div>
        <div className="image-after"></div>
        <div className="image-black"></div>
      </div> */}
      <div className="foreground">
      <section className="section announcement-cover">
        <div className="announcement-grid">
        <h3 className="announcement">Listen to Our New Single, Clouds!</h3>
        <div className='subgrid'>
          <img src={require('./images/clouds-cover-final.png')} alt="clouds-cover" className="clouds-cover"></img>
            <div class="link-grid-announcement">
              {/* Add className="button-disabled" to disable */}
              <a href="https://youtu.be/b4CIc8zcEpg" className="button announcement-button">YouTube</a>
              <a href="https://open.spotify.com/track/1NU2cFYwguZ0VhubWdJOLG?si=94d9fffbb03641cd" className="button announcement-button">Spotify</a>
              <a href="https://music.apple.com/us/album/clouds/1629841974?i=1629841975" className="button announcement-button">Apple Music</a>
            </div>
          </div>
        </div>
      </section>
        {/* <header className="header section">
          <h1 id="header-text">
            ACACIA
          </h1>
        </header> */}
        <section className="section about">
          <h2>About Us</h2>
          <p>
            Acacia is a four-part band created by Allison Schmuldt (Guitar, Piano, Trumpet, Ukulele, Vocals), Levi Barshinger (Sound), Ian Barshinger (Piano, Guitar, Violin, Mandolin, Vocals), and Micah Barshinger (Drum, Bass). Our music combines aspects of many genres, with pop and folk music having the most influence on our style. Right now, we’re working on recording our first album, Gold and Glass, which we hope to release later this year or early next year. You can contact us at acaciaband0@gmail.com.
          </p>
        </section>
        <section className="section music" id="music-section">
          <h2>Our Music</h2>
          <div class="link-grid">
            {/* Add className="button-disabled" to disable */}
            <a href="https://www.youtube.com/channel/UC_NU5dh9x1B-W7MauNkjCPQ" className="platform-button">YouTube</a>
            <a href="https://open.spotify.com/artist/2VmmN0ojbelEMwWrHQiYCA?si=GNs1DUrPQ8ilNvVBsB0W6A&dl_branch=1" className="platform-button">Spotify</a>
            <a href="https://music.apple.com/us/artist/acacia/1587966538" className="platform-button">Apple Music</a>
            <a href="https://music.amazon.com/artists/B09HQX7GW4/acacia?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_uNRJIXzGJEzWW1DUIjhUSDhxY" className="platform-button">Amazon Music</a>
            <a href="https://pandora.app.link/4iYF8xVaRlb" className="platform-button">Pandora</a>
          </div>
        </section>
        <section className="section newsletter">
          <div id="mc_embed_signup">
          <form action="https://gmail.us6.list-manage.com/subscribe/post?u=f980fb45a995a5d18c6ec34db&amp;id=696fcd268b" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll" className="form-box">
	          <label className="subscribe"  htmlFor="mce-EMAIL">Get Updated</label>
            <div className="inputs-box">
              <div className="input-container">
                <input type="email" value={Email} name="EMAIL" className="email-input" id="mce-EMAIL" placeholder="Email Address" required onChange={(e)=>setEmail(e.target.value)} />
              </div>
              <div className="hidden-input" aria-hidden="true"><input type="text" name="b_f980fb45a995a5d18c6ec34db_696fcd268b" tabIndex="-1" value="" /></div>
              <div className="clear"><input type="submit" value='→︁' name="subscribe" id="mc-embedded-subscribe" className="submit-button" /></div>
            </div>
            </div>
          </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;
